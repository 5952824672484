import React from "react";
import {Link} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const PublicationListItem = ({content}) => (
    <div className="contact-list-item card h-full grid grid-cols-1 md:grid-cols-2 gap-0">
        <div
            className="card-image border-b-20 border-secondary bg-gray-200">
            {content.relationships.image.localFile.childImageSharp ? (
                <GatsbyImage
                    image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                    alt={content.title} />
            ) : (
                <div className="flex items-center justify-center h-64 md:h-full px-6 pt-6 pb-1">
                    <i className="fas fa-file text-gray-400 text-8xl"><span className="hidden">Image fallback</span></i>
                </div>
            )}
        </div>
        <div className="card-content p-6 flex flex-col bg-primary text-white">
            <div className="flex-1 mb-4">
                <div className="relative mb-4 pb-4 before-underline before-bg-secondary">
                    <div className="h3 mb-2">{content.title}</div>
                </div>
            </div>

            <div className="flex-initial">
                <div className="buttons flex items-center justify-end -mx-2">
                      <div className="flex-initial px-2">
                          <Link
                              to={content.relationships.file.localFile.publicURL}
                              className="flex items-center justify-center h-12 w-12 bg-secondary rounded-full text-2xl"
                              title="Visualiser"
                              target="_blank"
                          >
                              <i className="fas fa-eye"><span className="hidden">Visualiser</span></i>
                          </Link>
                      </div>
                      <div className="flex-initial px-2">
                          <Link
                              to={content.relationships.file.localFile.publicURL}
                              className="flex items-center justify-center h-12 w-12 bg-secondary rounded-full text-2xl"
                              title="Télécharger"
                              download
                          >
                              <i className="fas fa-download"><span className="hidden">Télécharger</span></i>
                          </Link>
                      </div>
                </div>
            </div>
        </div>
    </div>
)

PublicationListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default PublicationListItem
